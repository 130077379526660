import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  title: {
    maxWidth: 300,
    fontSize: 13,
    lineHeight: '15px',
    color: '#151515',
    fontWeight: 700,
    marginBottom: 8,
  },
  info: {
    fontSize: 11,
    lineHeight: '14px',
    color: '#898A8D',
    marginBottom: 21,
  },
  name: {
    fontSize: 13,
    lineHeight: '15px',
    color: '#151515',
    marginBottom: 16,
  },
  upload: {
    display: 'flex',
    width: 120,
    height: 40,

    '& input': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
    },
  },
  preview: {
    maxHeight: 148,
    width: 'calc(100% / 2 - 10px)',
    marginRight: 20,
    marginBottom: 20,

    '&:nth-child(2n)': {
      marginRight: 0,
    },

    '& img': {
      width: '100%',
      height: '100%',
    },
  },

  preview__btn: {
    width: 32,
    height: 32,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    transition: 'background-color .3s ease-in-out',
    right: 8,
    top: 8,
    borderRadius: 0,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});
