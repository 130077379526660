import { API_URLS } from '~/utils/constants';
import { FITTING_SOLUTION_FIELDS } from '~/pages/fittingSolution/fields';
import { blockItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import FittingSolutionEntity from './components/FittingSolutionEntity';
import { createItemsFn, updateItemsFn } from '~/pages/fittingSolution/api';

const FITTING_SOLUTION_URL = API_URLS.fittingSolution;
const TITLE = 'Каталог фурнитуры';

const FittingSolution = new FittingSolutionEntity({
  title: TITLE,
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/fitting-solutions',
    enabled: true,
  },
  api: {
    list: { url: FITTING_SOLUTION_URL, method: 'get' },
    get: { url: FITTING_SOLUTION_URL, method: 'get' },
    update: { url: FITTING_SOLUTION_URL, method: 'put' },
    create: { url: FITTING_SOLUTION_URL, method: 'post' },
  },
  fields: FITTING_SOLUTION_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  deleteItemsFn,
  updateItemsFn,
});

export default FittingSolution;
