import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { EntityViewer } from 'icerockdev-admin-toolkit';
import React from 'react';
import CustomEntity from '~/common/modules/CustomEntity';

class ExtendedCustomEntity extends CustomEntity {
  @computed
  get CreatorBody() {
    this.createEmptyItem();

    return observer(() => {
      if (!this.canCreate) return this.forbiddenPlaceholder;

      return (
        <EntityViewer
          fields={this.fields}
          errors={this.editorFieldErrors}
          url={this.menu.url}
          onSave={this.createItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.createEmptyItem}
          cancelGetItem={this.getItemsCancel}
          viewable={this.viewable}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }
}

export default ExtendedCustomEntity;
