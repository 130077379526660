import React, { ChangeEvent, ReactElement, useCallback, useState, useEffect } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { Box, Button, FormControl, withStyles, WithStyles } from '@material-ui/core';
import styles from './styles';

// eslint-disable-next-line no-shadow
enum ERRORS {
  TOO_BIG = 'Максимальный размер файла {{replace}} МБ, выберите другой файл для загрузки',
  TOO_SMALL = 'Файл слишком мал',
  NOT_SUPPORTED = 'Допустимые для загрузки форматы файлов - {{replace}} выберите другой файл допустимого  формата',
}

const getError = (error: string) => (replacer: string) => error.replace('{{replace}}', replacer);

type IProps = IEntityFieldProps &
  WithStyles<typeof styles> & {
    renderImage: (string) => ReactElement;
    fileNameFromValue?: string;
  };

const EntityFileUpload = withStyles(styles)(
  ({
    handler,
    options,
    label,
    isEditing,
    error,
    classes,
    data,
    renderImage,
    name,
    fileNameFromValue,
  }: IProps) => {
    const [innerError, setInnerError] = useState('');
    const [fileName, setFileName] = useState('');
    const [image, setImage] = useState('');
    const imageKey = options?.keyProp ? data?.[options?.keyProp] : data?.modelKey ?? data?.imageKey;

    useEffect(() => {
      if (handler && imageKey !== undefined) {
        handler(imageKey);
      }
    }, []);

    const getBase64 = useCallback(
      (file: File): Promise<string> =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result?.toString() || '');
          reader.onerror = (err) => reject(err);
        }),
      []
    );

    const setImageData = async (imageData: string | File) => {
      const photo = typeof imageData !== 'string' ? await getBase64(imageData) : imageData;
      const img = new Image();
      img.src = photo;
      img.onload = () => {
        setImage(photo);
      };
    };

    useEffect(() => {
      (async () => {
        if (data && handler) {
          const file = data?.[name] || data?.file;
          const url = data?.modelUrl ?? (isEditing && data?.url) ?? data?.file?.modelUrl;
          if (file && typeof file !== 'string' && handler) {
            setFileName(file.name);
            setImageData(file);
          }
          if (url) {
            setImageData(url);
          }
        }
      })();
    }, []);

    const supportedExtensions = options?.allowedMimeType;
    const supportedExtensionsMessage =
      supportedExtensions?.map((string) => string.split('/').pop().toUpperCase()).join(', ') || '';

    const loadFile = useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        if (!handler) return;
        if (!e.target.files) return;

        setInnerError('');

        const file = e.target.files[0];

        if (!file) return;

        if (options?.maxSize && file.size > options.maxSize) {
          setInnerError(getError(ERRORS.TOO_BIG)(`${options.maxSize / 1024 / 1024}`));
          return;
        }

        if (options?.minSize && file.size < options.minSize) {
          setInnerError(ERRORS.TOO_SMALL);
          return;
        }

        if (options?.allowedMimeType && file.type && !options.allowedMimeType.includes(file.type)) {
          setInnerError(getError(ERRORS.NOT_SUPPORTED)(supportedExtensionsMessage));
          return;
        }

        if (!file.type && !options?.allowedMimeType.includes(file.name.split('.').pop())) {
          setInnerError(getError(ERRORS.NOT_SUPPORTED)(supportedExtensionsMessage));
          return;
        }

        setFileName(file.name);

        const photo: string = await getBase64(file);

        const img = new Image();
        img.src = photo;

        img.onload = () => {
          setImage(photo);
        };

        handler(file);
      },
      [handler, options]
    );

    const imageForView = image.length
      ? { url: image }
      : data?.[`${name}Url`] ?? (typeof data === 'string' && data);

    const preview = imageForView?.url || imageForView?.previewUrl || imageForView;

    const fileNameForView =
      fileName ||
      data?.fileName ||
      data?.[`${name}Name`] ||
      imageForView?.originalName ||
      fileNameFromValue ||
      'Файл не загружен';

    return (
      <div className={classes.formControl}>
        <FormControl>
          {isEditing && (
            <>
              <span className={classes.title}>{label}</span>
              <span className={classes.info}>
                {`${supportedExtensionsMessage} `}
                до
                {` ${options?.maxSize / 1024 / 1024} `}
                МБ
              </span>
            </>
          )}
          <span className={classes.name}>{fileNameForView}</span>
          {isEditing && (
            <>
              <div className={classes.upload} style={{ position: 'relative' }}>
                <Button
                  name={label}
                  type="button"
                  variant="contained"
                  color="primary"
                  style={{
                    display: 'flex',
                    width: 120,
                    height: 40,
                  }}
                >
                  Загрузить
                </Button>
                <input
                  id={label}
                  name={label}
                  type="file"
                  onChange={loadFile}
                  style={{ position: 'absolute' }}
                />
              </div>
              {(innerError || error) && (
                <Box color="error.main" fontSize={12}>
                  {innerError.toLocaleLowerCase() || error}
                </Box>
              )}
            </>
          )}
        </FormControl>
        {preview && renderImage ? (
          <div className={classes.preview}>{renderImage(preview)}</div>
        ) : (
          preview && <img src={preview} className={classes.preview} alt={`${label}IMG`} />
        )}
      </div>
    );
  }
);

export { EntityFileUpload };
