import { minMax, regexpTest } from '~/utils/validators';

export const maxOneThousandsSymbols = minMax(0, 1000)('Поле должно содержать до 1000 символов');

export const maxOneHundredSymbols = minMax(0, 100)('Поле должно содержать до 100 символов');

export const maxTenSymbols = minMax(0, 10)('Поле должно содержать до 10 символов');

export const maxThirtySymbols = minMax(0, 30)('Поле должно содержать до 30 символов');

export const onlyDigitsAndSpecialSymbols = regexpTest(/^[0-9!@#$%^&*/)(+=._-]+$/)(
  'Поле должно содержать только цифры и спецсимволы'
);

export const maxTenSymbolsAndOnlyDigitsAndSpecialSymbols = (val: string) =>
  onlyDigitsAndSpecialSymbols(val) || maxTenSymbols(val);
