import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { EntityFileUpload } from '~/common/components/EntityFileUpload';
import EntityImage from '~/common/components/EntityImage';

type SectionUploadProps = IEntityFieldProps & { fileNameFromValue?: string };

const SectionImageUpload: FC<SectionUploadProps> = ({ isEditing, ...props }) => (
  <EntityFileUpload
    {...props}
    isEditing={isEditing}
    renderImage={(val) => val && <EntityImage width={130} height={130} val={val} />}
  />
);

const SectionFileUpload: FC<SectionUploadProps> = ({ isEditing, ...props }) => (
  <EntityFileUpload {...props} isEditing={isEditing} renderImage={() => <></>} />
);

export { SectionFileUpload, SectionImageUpload };
