import ExtendedCustomEntity from '~/common/modules/ExtendedCustomEntity';

class ColorSolutionEntity extends ExtendedCustomEntity {
  emptyText =
    'В системе пока нет созданных цветовых решений. Для начала работы с каталогом цветовых решений создайте цветовое решение';

  deletionText = 'Вы уверены, что хотите удалить цветовое решение?';

  blockText = 'Вы уверены, что хотите заблокировать цветовое решение?';

  unblockText = 'Вы уверены, что хотите разблокировать цветовое решение?';
}

export default ColorSolutionEntity;
