import React from 'react';
import { action, computed, observable } from 'mobx';
import { INotification } from 'icerockdev-admin-toolkit/src/application/types/notification';
import { observer } from 'mobx-react';
import { Notifications } from 'icerockdev-admin-toolkit/dist/application/modules/common/Notification';
import { Notification } from '~/common/components/Notification';

export class CustomNotifications extends Notifications {
  @observable notification: INotification = {
    show: false,
    message: '',
    type: 'info',
    timeout: 6000,
  };

  @action
  hideNotification = (): void => {
    this.notification.show = false;
  };

  @action
  showError = (message: INotification['message']): void => {
    this.notification.show = true;
    this.notification.message = message;
    this.notification.type = 'error';
  };

  @action
  showSuccess = (message: INotification['message']): void => {
    this.notification.show = true;
    this.notification.message = message;
    this.notification.type = 'success';
  };

  @computed
  get Output(): () => JSX.Element {
    return observer(() => (
      <Notification
        hideNotification={this.hideNotification}
        message={this.notification.message}
        type={this.notification.type}
        timeout={this.notification.timeout}
        show={this.notification.show}
      />
    ));
  }
}
