import { AccountEntity } from '~/pages/account/components/AccountEntity';
import { USER_FIELDS } from '~/pages/user/fields';
import { UserRoles } from '~/pages/user/constants';

const Account = new AccountEntity({
  title: 'Мой аккаунт',
  fields: USER_FIELDS,
  menu: {
    enabled: true,
    url: '/my-account',
    label: 'Мой аккаунт',
  },
  roles: [UserRoles.user],
  permissions: {
    list: [UserRoles.user],
  },
});

export default Account;
