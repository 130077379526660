import { API_URLS } from '~/utils/constants';
import { COLOR_SOLUTION_FIELDS } from '~/pages/colorSolution/fields';
import { blockItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import ColorSolutionEntity from './components/ColorSolutionEntity';
import { createItemsFn, updateItemsFn } from '~/pages/colorSolution/api';

const COLOR_SOLUTION_URL = API_URLS.colorSolution;
const TITLE = 'Каталог цветовых решений';

const ColorSolution = new ColorSolutionEntity({
  title: TITLE,
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/color-solutions',
    enabled: true,
  },
  api: {
    list: { url: COLOR_SOLUTION_URL, method: 'get' },
    get: { url: COLOR_SOLUTION_URL, method: 'get' },
    update: { url: COLOR_SOLUTION_URL, method: 'put' },
    create: { url: COLOR_SOLUTION_URL, method: 'post' },
  },
  fields: COLOR_SOLUTION_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  deleteItemsFn,
  updateItemsFn,
});

export default ColorSolution;
