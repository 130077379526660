import React, { FC } from 'react';

import { CircularProgress } from '@material-ui/core';
import styles from './styles.module.scss';

type IProps = {
  isLoading: boolean;
  text: string;
};

const EmptyList: FC<IProps> = ({ isLoading, text }) => (
  <div className={styles.empty}>
    {isLoading ? <CircularProgress /> : <span className={styles.empty__text}>{text}</span>}
  </div>
);

export default EmptyList;
