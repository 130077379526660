import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

const FullNameField: FC<IEntityFieldProps> = ({ data }) => {
  if (!data) return null;

  const { name, surname, secondName } = data;

  return <span>{`${surname} ${name} ${secondName || ''}`}</span>;
};

export default FullNameField;
