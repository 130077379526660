import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const PRIMARY_COLOR = '#0069B4';
const SECONDARY_COLOR = '#E21212';

export default createMuiTheme({
  palette: {
    primary: { main: PRIMARY_COLOR },
    secondary: { main: SECONDARY_COLOR },
  },
});
