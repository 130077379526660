import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { Box, Button, FormControl, IconButton, withStyles, WithStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';

// eslint-disable-next-line no-shadow
enum ERRORS {
  TOO_BIG = 'Максимальный размер изображения {{replace}} МБ, выберите другой файл для загрузки',
  TOO_SMALL = 'Файл слишком мал',
  TOO_MANY = 'Файлов слишком много, загрузите не больше 8 изображений',
  NOT_SUPPORTED = 'Допустимые для загрузки форматы файлов - {{replace}} выберите другой файл допустимого  формата',
}

const getError = (error: string) => (replacer: string) => error.replace('{{replace}}', replacer);

type IProps = IEntityFieldProps &
  WithStyles<typeof styles> & { renderImage: (string) => ReactElement };

const SectionMultyFileUpload = withStyles(styles)(
  ({ handler, options, label, isEditing, error, classes, data, name }: IProps) => {
    const [innerError, setInnerError] = useState('');
    const values = data?.[`${name}Url`];

    const isCreating = window.location.pathname.includes('create');

    const [previewImages, setPreviewImages] = useState<{ id: string; photo: string }[]>(
      values && !isCreating ? values.map((value, i) => ({ id: `${i}`, photo: value.url })) : []
    );
    const [images, setImages] = useState<{ id: string; file: File }[]>(
      data?.[name] && !isCreating ? data[name].map((el, i) => ({ id: `${i}`, file: el })) : []
    );

    const supportedExtensions = options?.allowedMimeType?.join(', ').toUpperCase();
    const supportedExtensionsMessage = supportedExtensions?.replaceAll('IMAGE/', '') || '';

    const getBase64 = useCallback(
      (file: File): Promise<string> =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result?.toString() || '');
          reader.onerror = (err) => reject(err);
        }),
      []
    );

    const fileValidate = (file): boolean => {
      if (options?.maxSize && file.size > options.maxSize) {
        setInnerError(getError(ERRORS.TOO_BIG)(`${options.maxSize / 1024 / 1024}`));
        return false;
      }

      if (options?.minSize && file.size < options.minSize) {
        setInnerError(ERRORS.TOO_SMALL);
        return false;
      }

      if (options?.allowedMimeType && file.type && !options.allowedMimeType.includes(file.type)) {
        setInnerError(getError(ERRORS.NOT_SUPPORTED)(supportedExtensionsMessage));
        return false;
      }

      if (!file.type && !options?.allowedMimeType.includes(file.name.split('.').pop())) {
        setInnerError(getError(ERRORS.NOT_SUPPORTED)(supportedExtensionsMessage));
        return false;
      }

      return true;
    };

    const loadFile = useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        setInnerError('');

        const { files } = e.target;

        if (!files) return;

        if (files.length > options?.maxFiles || images.length + files.length > options?.maxFiles) {
          setInnerError(getError(ERRORS.TOO_MANY)(supportedExtensionsMessage));
          return;
        }

        Array.from(files).forEach(async (file) => {
          if (!fileValidate(file)) return;

          const photo: string = await getBase64(file);

          const img = new Image();
          img.src = photo;

          const id = Math.random().toString(36).substring(2);

          img.onload = () => {
            setPreviewImages((prevState) => [...prevState, { id, photo }]);
            setImages((prevState) => [...prevState, { id, file }]);
          };
        });
      },
      [handler, options]
    );

    const handleDelete = (id) => {
      setPreviewImages((prevState) => prevState.filter((el) => el.id !== id));
      setImages((prevState) => prevState.filter((el) => el.id !== id));
    };

    useEffect(() => {
      handler!(images.map(({ file }) => file));
    }, [images]);

    if (!isEditing && !previewImages.length) return <div>Изображения отсутствуют</div>;

    return (
      <div>
        <FormControl>
          {isEditing && (
            <>
              <span className={classes.title}>{label}</span>
              <span className={classes.info}>
                До {` ${options?.maxFiles} изображений `}
                {`${supportedExtensionsMessage} `}
                до
                {` ${options?.maxSize / 1024 / 1024} `}
                МБ
              </span>
            </>
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {previewImages.map(({ id, photo }, i) => (
              <div key={id} className={classes.preview} style={{ position: 'relative' }}>
                {isEditing && (
                  <IconButton
                    onClick={() => handleDelete(id)}
                    className={classes.preview__btn}
                    style={{ position: 'absolute' }}
                  >
                    <CloseIcon htmlColor="#FFF" />
                  </IconButton>
                )}
                <img key={id} src={photo} alt={`Загруженное изображение ${i + 1}`} />
              </div>
            ))}
          </div>
          {isEditing && images.length < options?.maxFiles && (
            <>
              <div className={classes.upload} style={{ position: 'relative' }}>
                <Button
                  name={label}
                  type="button"
                  variant="contained"
                  color="primary"
                  style={{
                    display: 'flex',
                    width: 120,
                    height: 40,
                  }}
                >
                  Загрузить
                </Button>

                <input
                  id={label}
                  name={label}
                  type="file"
                  multiple
                  onChange={loadFile}
                  style={{ position: 'absolute' }}
                />
              </div>
              {(innerError || error) && (
                <Box color="error.main" fontSize={12}>
                  {innerError || error}
                </Box>
              )}
            </>
          )}
        </FormControl>
      </div>
    );
  }
);

export { SectionMultyFileUpload };
