import { path } from 'ramda';
import { AxiosError } from 'axios';
import HttpStatusCode from 'http-status-typed';
import { UNAUTHORIZED } from 'icerockdev-admin-toolkit';

const noInternetConnection = (e: AxiosError) => {
  if (!e?.response) {
    throw new Error(
      'Соединение с сервером и/или сетью прервано. Пожалуйста, проверьте соединение с сетью или попробуйте войти позже'
    );
  }
};

export const jwtExpired = (e: AxiosError): never => {
  const unauthorizedError =
    (path(['response', 'data', 'data', 0, 'message'], e) as string) ??
    (e?.response as any)?.data?.message;

  if (!unauthorizedError && e?.response?.status === HttpStatusCode.UNAUTHORIZED) {
    throw new Error(UNAUTHORIZED);
  }

  if (unauthorizedError) {
    throw new Error(unauthorizedError);
  }

  noInternetConnection(e);

  throw e;
};

export const loginError = (e: AxiosError) => {
  const validationError = path(['response', 'data', 'data', 0, 'message'], e) as string;

  if (validationError) {
    throw new Error(validationError);
  }

  noInternetConnection(e);

  throw new Error(e?.response?.data?.message);
};
