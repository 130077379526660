import React, { FC, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

const PhoneField: FC<IEntityFieldProps> = ({ label, value, handler, error, isEditing }) => {
  const onChange = useCallback(
    (event) => {
      if (!handler) return;

      handler(event.target.value.replace(/[^\d]/gim, ''));
    },
    [handler]
  );

  return isEditing ? (
    <div>
      <InputMask
        mask="+7 (999) 999-99-99"
        value={value ? value.toString() : ''}
        onChange={onChange}
      >
        {() => <TextField label={label} error={!!error} helperText={error} variant="outlined" />}
      </InputMask>
    </div>
  ) : (
    <div>
      {value ? (
        `+${value[0]} (${value.substring(1, 4)}) ${value.substring(4, 7)}-${value.substring(
          7,
          9
        )}-${value.substring(9, 11)}`
      ) : (
        <div>&nbsp;</div>
      )}
    </div>
  );
};

export default PhoneField;
