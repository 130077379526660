import React, { FC, Fragment, useMemo } from 'react';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styles from '~/pages/section/components/SectionViewer/styles';
import EntityImage from '~/common/components/EntityImage';

const useStyles = makeStyles(styles);

const SectionColorSolutionList: FC<IEntityFieldProps> = ({ data, name, value }) => {
  const classes = useStyles();

  const entity = useEntity();
  const colorSolutions = entity?.referenceData?.colorSolutions as Record<string, any>;

  const fields = useMemo(
    () =>
      data?.[name.replace('Url', '')]?.map(({ colorSolutionId }, i) => {
        const solution = Object.values(colorSolutions || {}).find(
          (el) => el?.id === colorSolutionId
        );

        const currValue = value.find((el) => el.id === colorSolutionId);

        return {
          id: i,
          type: 'string',
          label: 'Цвет',
          name: solution?.name,
          fileName: currValue?.originalName,
          previewUrl: currValue?.previewUrl,
        };
      }),
    [colorSolutions]
  );

  if (!value || !value.length) {
    return <div>Для данного профиля отсутствуют цветовые решения.</div>;
  }

  return (
    <div className={classes.grid}>
      {fields &&
        fields.map((field) => (
          <Fragment key={`sectionColorSolutions-${field.id}`}>
            <div
              className={classes.field}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <div className="label">{field.label}</div>
                <div className="field">{field.name}</div>
                <div className="label">{field.fileName}</div>
              </div>

              {field.previewUrl && <EntityImage width={130} height={130} val={field.previewUrl} />}
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export { SectionColorSolutionList };
