import React, { ChangeEvent, FC } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

type ITabs = {
  title: string;
  value: string;
};

interface IProps {
  tabs: ITabs[];
  activeTab: number;
  onChange: (event: ChangeEvent<{}>, value: any) => void;
}

const CommonTabs: FC<IProps> = ({ tabs, activeTab, onChange }) => (
  <Tabs
    indicatorColor="primary"
    value={activeTab}
    onChange={onChange}
    style={{ borderBottom: '1px solid #EDEDED' }}
  >
    {tabs.map(({ title }) => (
      <Tab key={title} label={title} />
    ))}
  </Tabs>
);

export { CommonTabs };
