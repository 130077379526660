import ExtendedCustomEntity from '~/common/modules/ExtendedCustomEntity';

class FittingSolutionEntity extends ExtendedCustomEntity {
  emptyText =
    'В системе пока нет созданных вариантов фурнитуры. Для начала работы с каталогом вариантов фурнитуры создайте вариант фурнитуры';

  deletionText = 'Вы уверены, что хотите удалить вариант фурнитуры?';

  blockText = 'Вы уверены, что хотите заблокировать вариант фурнитуры?';

  unblockText = 'Вы уверены, что хотите разблокировать вариант фурнитуры?';
}

export default FittingSolutionEntity;
