/* eslint-disable camelcase */
import { Entity, EntityViewer } from 'icerockdev-admin-toolkit';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { getUserProfileFn } from '~/auth/api';
import styles from './styles.module.scss';
import Agreetment from './access/pdf/Agreetment.pdf';
import Personal_Date from './access/pdf/Personal_Date.pdf';

export class AccountEntity extends Entity {
  @action
  onMount = () => null;

  @computed
  get output() {
    return observer(() => {
      const [user, setUser] = useState({});

      const getUser = async () => {
        const userFromServer = await this.parent?.auth?.withToken(getUserProfileFn, {});
        setUser(userFromServer);
      };

      useEffect(() => {
        getUser();
      }, []);

      const accountFields = useMemo(
        () => this.fields.filter((field) => field.name !== 'status'),
        [this.fields]
      );

      return (
        <div className={styles.wrap}>
          <div className={styles.wrap_title}>
            <this.ListHeadTitle />
          </div>
          {user && (
            <div className={styles.entity}>
              <EntityViewer
                id=""
                fields={accountFields}
                url={this.menu.url}
                errors={{}}
                onSave={() => null}
                onCancel={() => null}
                onResetFieldError={() => null}
                isEditing={false}
                isLoading={false}
                setEditorData={() => null}
                data={user}
                getItem={() => null}
                cancelGetItem={() => null}
                withToken={() => null}
                viewable
                entity={this}
              />
            </div>
          )}
          <div className={styles.entity_footer}>
            <a href={Agreetment} target="_blank">
              Пользовательское соглашение
            </a>
            <p>/</p>
            <a href={Personal_Date} target="_blank">
              Политика обработки данных
            </a>
          </div>
        </div>
      );
    });
  }
}
