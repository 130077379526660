/* eslint-disable @typescript-eslint/no-empty-function */
import { action, computed, flow } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import {
  Entity,
  ENTITY_ERRORS,
  ENTITY_REFERENCE_FIELDS,
  IEntityFetchFunctionResult,
} from 'icerockdev-admin-toolkit';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomEntity from '~/common/modules/CustomEntity';
import { sectionsModules } from '~/pages/section/constants';
import { SectionModules } from '~/pages/section/components/SectionModules';
import { SectionViewer } from '~/pages/section/components/SectionViewer';
import { DefaultStatus } from '~/utils/constants';

class SectionEntity extends CustomEntity {
  emptyText =
    'В системе пока нет созданных профилей. Для начала работы с каталогом профилей создайте профиль';

  @action
  fetchItems = () => {
    this.fetchItemsCancel();

    this.fetchItemsInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';
      this.selected = [];

      try {
        // loading entity
        if (!this.api?.list?.url || !this.fetchItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const filter = this.getFilters();

        const result: IEntityFetchFunctionResult = yield this.parent?.auth?.withToken(
          this.fetchItemsFn,
          {
            url: this.api?.list?.url || '',
            filter,
            page: this.page,
            count: this.items,
            sortBy: this.sortBy,
            sortDir: this.sortDir,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.data = result?.data?.list || [];
        this.filterData = result?.filterData || {};
        this.totalCount = result?.data?.totalCount || 0;

        // Loading references (if any)
        const references = this.fields
          .filter(
            (field) =>
              field.type &&
              Object.prototype.hasOwnProperty.call(ENTITY_REFERENCE_FIELDS, field.type) &&
              this.references[field.name]?.getMany
          )
          .map(async (field) => ({
            [field.name]: await this.references[field.name].getMany(this),
          }));

        const refResults = yield Promise.all(references);

        this.referenceData = refResults.reduce(
          (obj: Record<string, any>, res: Record<string, any>) => ({
            ...obj,
            ...res,
          }),
          {}
        );

        // updating field reference data
        this.fields = this.fields.map((field) =>
          this.referenceData[field.name]
            ? {
                ...field,
                options: { ...field.options, referenceData: this.referenceData[field.name] },
              }
            : field
        );

        // finished
        this.isLoading = false;
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @computed
  get CreatorBody() {
    return observer(() => {
      if (!this.canCreate) return this.forbiddenPlaceholder;

      return (
        <SectionModules
          modules={sectionsModules}
          errors={this.editorFieldErrors}
          url={this.menu.url}
          onSave={this.createItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.createEmptyItem}
          cancelGetItem={this.getItemsCancel}
          viewable={this.viewable}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get ViewerBody() {
    return observer(({ id }: { id: string }) => {
      if (!this.canView) return this.forbiddenPlaceholder;

      return (
        <SectionViewer
          id={id}
          url={this.menu.url}
          errors={this.editorFieldErrors}
          onSave={() => {}}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing={false}
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.getItem}
          cancelGetItem={this.getItemsCancel}
          withToken={this.parent?.auth?.withToken}
          viewable={this.viewable}
          entity={this}
        />
      );
    });
  }

  @computed
  get EditorBody() {
    return observer(({ id }: { id: string }) => {
      if (!this.canCreate) return this.forbiddenPlaceholder;

      return (
        <SectionModules
          modules={sectionsModules}
          id={id}
          errors={this.editorFieldErrors}
          url={this.menu.url}
          onSave={this.updateItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.getItem}
          cancelGetItem={this.getItemsCancel}
          withToken={this.parent?.auth?.withToken}
          viewable={this.viewable}
          entity={this}
          isEditing
        />
      );
    });
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => {
      const itemStatus = useMemo(
        () => this.data.find((el) => el.id === +id)?.status,
        [id, this.data]
      );

      const { t } = useTranslation();

      const handleMainButtonClick = useCallback(async () => {
        if (itemStatus === DefaultStatus.Blocked) {
          await this.deleteItem(id || 0);
        }
        if (itemStatus === DefaultStatus.Active) {
          await this.blockItem(id || 0);
        }
      }, [id, itemStatus]);

      const handleUnblockButtonClick = useCallback(async () => {
        await this.unblockItem(id);
      }, [id, itemStatus]);

      return (
        <>
          <Button
            color="primary"
            variant="contained"
            to={`${this.menu.url}/${id}/edit`}
            component={RouterLink}
            style={{ marginRight: 16 }}
          >
            {t('buttons:Edit')}
          </Button>
          <Button
            onClick={handleMainButtonClick}
            variant="contained"
            color="secondary"
            type="button"
            style={{ marginRight: 10 }}
          >
            {itemStatus !== DefaultStatus.Active ? 'Удалить' : 'Блокировать'}
          </Button>
          {itemStatus === DefaultStatus.Blocked && (
            <Button
              onClick={handleUnblockButtonClick}
              variant="contained"
              color="secondary"
              type="button"
              style={{ marginRight: 10 }}
            >
              Разблокировать
            </Button>
          )}
        </>
      );
    });
  }
}

export default SectionEntity;
