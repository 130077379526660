import { USER_FIELDS } from '~/pages/user/fields';
import { blockItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import { UserRoles } from '~/pages/user/constants';

import { UserEntity } from '~/pages/user/components/UserEntity';
import { API_URLS } from '~/utils/constants';
import { createItemsFn, updateItemsFn } from '~/pages/user/api';

const USER_URL = API_URLS.user.user;

const User = new UserEntity({
  title: 'Пользователи',
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: 'Пользователи',
    url: '/users',
    enabled: true,
  },
  api: {
    list: { url: USER_URL, method: 'get' },
    get: { url: USER_URL, method: 'get' },
    update: { url: USER_URL, method: 'put' },
    create: { url: USER_URL, method: 'post' },
  },
  roles: [UserRoles.admin],
  permissions: {
    list: [UserRoles.admin],
  },
  fields: USER_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  deleteItemsFn,
  blockItemsFn,
  unblockItemsFn,
});

export default User;
