import {
  IAuthLogoutFn,
  IAuthPasswRestoreFn,
  IAuthPasswUpdateFn,
  IJWTAuthRequestFn,
  IJWTTokenRefreshFn,
  UNAUTHORIZED,
} from 'icerockdev-admin-toolkit';

import { axios } from '~/utils/axios';
import CustomError from '~/utils/customError';
import { API_URLS } from '~/utils/constants';

export const getUserProfileFn = async ({ token }: { token: string }) => {
  const {
    data: { data: user },
  } = await axios.get(API_URLS.user.profile, { headers: { Authorization: token } });

  return user;
};

export const authRequestFn: IJWTAuthRequestFn = async (email: string, password: string) => {
  const {
    data: {
      data: { accessToken, refreshToken },
    },
  } = await axios.post(API_URLS.auth.signin, { email: email.trim(), password });

  if (!accessToken || !refreshToken) {
    throw new CustomError('Ошибка', 'Неверный логин или пароль');
  }

  const user = await getUserProfileFn({ token: `Bearer ${accessToken}` });

  return {
    user,
    tokens: {
      access: accessToken,
      refresh: refreshToken,
    },
    error: '',
  };
};

export const tokenRefreshFn: IJWTTokenRefreshFn = async (refreshToken) => {
  try {
    const result = await axios.post(API_URLS.auth.refresh, { refreshToken });

    if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }

    return {
      access: result.data.data.accessToken,
      refresh: result.data.data.refreshToken,
    };
  } catch (e) {
    return {
      access: '',
      refresh: '',
    };
  }
};

export const authLogoutFn: IAuthLogoutFn = async () => {
  const { refresh } = JSON.parse(localStorage.getItem('tokens') ?? '');

  try {
    const result = await axios.post(API_URLS.auth.logout, {
      refreshToken: refresh,
    });

    if (!result.data.success) {
      throw new Error(UNAUTHORIZED);
    }

    return result;
  } catch (e) {
    return {
      error: e.message,
    };
  }
};

export const authPasswRestoreFn: IAuthPasswRestoreFn = async (email) => {
  try {
    const result = await axios.post(API_URLS.auth.restore, { email: email.trim() });

    if (!result?.data?.data || !(result?.data?.data?.success || result?.data?.data?.isSuccess)) {
      throw new Error(result?.data?.data?.message);
    }

    return {
      error: '',
    };
  } catch (e) {
    return {
      error: e.message,
    };
  }
};

export const authPasswUpdateFn: IAuthPasswUpdateFn = async (confirmationToken, password) => {
  try {
    const result = await axios.put(API_URLS.auth.restore, {
      confirmationToken,
      password,
      passwordRepeat: password,
    });

    if (!result?.data?.data || !(result?.data?.data?.success || result?.data?.data?.isSuccess)) {
      throw new Error(result?.data?.data?.message);
    }

    return {
      error: '',
    };
  } catch (e) {
    return {
      error: e.message,
    };
  }
};
